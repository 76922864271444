"use client";
import React, { useState, useEffect, useRef } from 'react';
import { getCookie, setCookie, deleteCookie } from 'cookies-next';
import type { CookieSerializeOptions } from 'cookie';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import type { Statuses } from './api/get-status/route';
import GoogleAdsense from '@/components/google-adsense';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL!;
const APP_URL_VANIRA = process.env.NEXT_PUBLIC_APP_URL_VANIRA!;

export default function Content() {

  const cookieOptions: CookieSerializeOptions = {
    secure: false, sameSite: 'strict', path: '/',
    maxAge: 60 * 60 * 24 * 365,
  };

  const [ archives, setArchives ] = useState<React.JSX.Element[]>([]);
  const spaceUrlInput = useRef<HTMLInputElement | null>(null);
  const statuses = useRef<Statuses>({});
  let ignore = false;

  useEffect(() => {
    if ( ignore ) return;

    const cookie = getCookie('archives', cookieOptions);
    if (cookie) {
      statuses.current = JSON.parse(cookie);
    }else setCookie('archives', JSON.stringify(statuses.current), cookieOptions);
    setArchives( updateHistoryElement( statuses.current ) );

    setInterval(async () => {
      const space_ids = Object.keys(statuses.current);
      if ( space_ids.length === 0 ) return;

      let expiryCount = 0;
      for ( let space_id of space_ids ) {
        const expiry = new Date( statuses.current[space_id].registered_at );
        if ( expiry.getTime() < (Date.now() - 1000 * 60 * 60 * 24) ) {
          expiryCount++;
          delete statuses.current[space_id];
        }
      }
      if ( expiryCount > 0 ) {
        setCookie('archives', JSON.stringify(statuses.current), cookieOptions);
        setArchives( updateHistoryElement( statuses.current ) );
      }

      let finishedCount = 0;
      for ( let space_id of space_ids ) {
        if ( statuses.current[space_id].status === 'finished' ) finishedCount++;
      }
      if ( finishedCount === space_ids.length ) return;

      const newStatuses = await getStatus( space_ids );
      if ( Object.keys(newStatuses).length === 0 ) return;

      const newArchives = updateHistoryElement( newStatuses );
      statuses.current = newStatuses;
      if ( newArchives.length === 0 ) return;

      setCookie('archives', JSON.stringify(statuses.current), cookieOptions);
      setArchives( newArchives );
      //console.log( statuses.current );
    }, 1000);

    return () => { ignore = true; }
  }, []);

  function updateHistoryElement( statuss: Statuses ): React.JSX.Element[] {
    const newArchives: React.JSX.Element[] = [];
    for ( const [ id, status ] of Object.entries( statuss ) ) {
      newArchives.push(
        <tr key={id} className="w-full border-b border-dotted border-cyan-700">
          <td className="text-xl w-full lg:w-[40%] block lg:table-cell">
            {
              status.status === 'finished' ?
              <a href={ 'https://twitter.com/i/spaces/' + id } target="_blank" rel="noopener noreferrer">
                  { status.title || "" }
              </a> :
              <a href={ 'https://twitter.com/i/spaces/' + id } target="_blank" rel="noopener noreferrer">
                https://twitter.com/i/spaces/{ id }
              </a>
            }
          </td>
          <td className="text-xl w-full lg:w-[20%] block lg:table-cell text-center lg:text-left">{ status.name || "--" }</td>
          <td className="text-xl w-full lg:w-[10%] block lg:table-cell text-center">{ status.user_id || "--" }</td>
          <td className="text-xl w-full lg:w-[15%] block lg:table-cell text-center">
            { status.status === 'waiting' && '待機中' }
            { status.status === 'downloading' && '処理中' }
            { status.status === 'finished' && '処理完了' }
            { status.status === 'failed' && '問題発生' }
          </td>
          <td className="text-xl w-full lg:w-[5%] block lg:table-cell text-center">{ status.waiting_number || '--' }</td>
          <td className="text-xl w-full lg:w-[15%] block lg:table-cell text-center">
            <Button className="px-[25px] text-xl block w-full lg:inline-block" variant="default"
              disabled={ status.status !== 'finished' }
              onClick={ () => {
                const url = APP_URL_VANIRA + '/api/download?space_id=' + id;
                location.assign( url );
              }}
            >ダウンロード</Button>
          </td>
        </tr>
      );
    }
    return newArchives;
  }

  async function registerArchive( space_id: string ) {
    return new Promise<Statuses>((resolve) => {
      fetch( APP_URL + '/api/register_archive', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ space_id })
      }).then( async (response) => {
        if ( response.ok ) {
          resolve( await response.json() );
        }else resolve( {} );
      });
    });
  }

  async function getStatus( space_ids: string[] ) {
    return new Promise<Statuses>((resolve) => {
      fetch( APP_URL + '/api/get-status', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ space_ids })
      }).then( async (response) => {
        if ( response.ok ) {
          resolve( await response.json() );
        }else resolve( {} );
      });
    });
  }

  return (
    <>
      <div className="flex w-full items-center space-x-2 my-4">
        <Input ref={ spaceUrlInput } id="test_235" className="text-xl bg-slate-900" type="text" placeholder="https://twitter.com/i/spaces/○○○○○○○○○" />
        <Button className="px-[25px] text-xl"
          variant="default"
          onClick={ async () => {
            const inputValue = spaceUrlInput.current?.value;
            const patern = /^https:\/\/(twitter|x)\.com\/i\/spaces\/.*/;
            if ( inputValue && patern.test( inputValue ) ) {
              const spaceUrl = new URL( inputValue );
              const space_id = spaceUrl.pathname.split(/\/|\?/)[3];
              if ( space_id in statuses.current ) {
                alert( '既に実行済みのアーカイブです。' ); return;
              }
              const register_space = await registerArchive( space_id );
              spaceUrlInput.current!.value = '';
              for ( const [ id, status ] of Object.entries( register_space ) ) {
                statuses.current[id] = status;
              }
            }else alert( 'URLの形式が正しくありません。' );
          }}
        >実行</Button>
      </div>
      <aside className="flex items-center mb-[20px] mt-[30px]">
        <span className="w-full lg:w-1/2 xl:w-1/3 inline-block">
          <GoogleAdsense />
        </span>
        <span className="w-1/2 xl:w-1/3 hidden lg:inline-block">
          <GoogleAdsense />
        </span>
        <span className="w-1/3 hidden lg:inline-block">
          <GoogleAdsense />
        </span>
      </aside>
      { archives.length > 0 &&
        <>
          <h2 className="text-center text-3xl my-6">ダウンロード履歴</h2>
          <table cellPadding={ 10 } className="w-full mb-[25px] bg-slate-900">
            <thead className='w-full border-b-2 border-double border-cyan-700'>
              <tr>
                <th className="text-xl w-full lg:w-[40%] block lg:table-cell text-center lg:text-left">スペースタイトル</th>
                <th className="text-xl w-full lg:w-[20%] block lg:table-cell text-center lg:text-left">ユーザー名</th>
                <th className="text-xl w-full lg:w-[10%] block lg:table-cell text-center">ユーザーID</th>
                <th colSpan={2} className="text-xl w-full lg:w-[10%] block lg:table-cell text-center">ステータス／待機数</th>
                <th className="text-xl w-full lg:w-[15%] block lg:table-cell text-center">操作</th>
              </tr>
            </thead>
            <tbody className='w-full'>
              { archives }
            </tbody>
          </table>
        </>
      }
    </>
  )
}